import React from "react";

export const Services = (props) => {
  return (
    <div id="services">
      <div className="container">
        <div className="section-title text-center">
          <h2>Our Services</h2>
        </div>
          {props.data
            ? 
              <div className="row">
                <div key={`${props.data[0].name}-0`} className="col-md-6">
                  <div className="service">
                    <div className="service-image">
                      {" "}
                      <i className={props.data[0].icon}></i>
                    </div>
                    <div className="service-content">
                      <h3>{props.data[0].title}</h3>
                      <div className="list-style">
                        <div>
                          <ul>
                            {props.data[0].text.map((t, j) => (
                                  <li key={`${t}-${j}`}>{t}</li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div key={`${props.data[1].name}-1`} className="col-md-6">
                  <div className="service">
                    <div className="service-image">
                      {" "}
                      <i className={props.data[1].icon}></i>
                    </div>
                    <div className="service-content">
                      <h3>{props.data[1].title}</h3>
                      <div className="list-style">
                        <div>
                          <ul>
                            {props.data[1].text.map((t, j) => (
                                  <li key={`${t}-${j}`}>{t}</li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            : "loading"}
          {props.data
            ? 
              <div className="row">
                <div key={`${props.data[2].name}-2`} className="col-md-6">
                  <div className="service">
                    <div className="service-image">
                      {" "}
                      <i className={props.data[2].icon}></i>
                    </div>
                    <div className="service-content">
                      <h3>{props.data[2].title}</h3>
                      <div className="list-style">
                        <div>
                          <ul>
                            {props.data[2].text.map((t, j) => (
                                  <li key={`${t}-${j}`}>{t}</li>
                                ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          : "loading"}
      </div>
    </div>
  );
};
